import * as React from "react"

class NotFound extends React.Component {

    render() {
        return (
            <h2>404 Not Found</h2>
        );
    }

}

export default NotFound