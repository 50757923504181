import * as React from "react";
import {bindActionCreators, Dispatch} from "redux";
import * as AuthAction from "../action/auth";
import {connect} from "react-redux";
import * as ls from "local-storage";
import {Redirect} from "react-router";
import {AuthState} from "../reducer/type/auth";
import StateInterface from "../reducer/type";
import LoadingScreen from "../component/LoadingScreen";

interface StatePropsInterface {
    auth: AuthState
}

interface DispatchPropsInterface {
    actions: {
        postLogoutAction: any
    }
}

const mapStateToProps = (state: StateInterface) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        postLogoutAction: AuthAction.postLogoutAction,
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface

interface CurrentState {
    loading: boolean
}

class Logout extends React.Component <PropsInterface, CurrentState> {

    constructor (props) {
        super(props)
        this.props.actions.postLogoutAction();
        this.state = {
            loading: true
        }
    }

    render() {
        if (typeof ls.get<string>('token') != 'string') {
            window.location.replace(window.location.origin);
        }

        let loadingScreen;
        if (this.state.loading) {
            loadingScreen = (<LoadingScreen/>)
        }

        return (
            <div className="container-fluid overflow-hidden">
                {loadingScreen}
            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(Logout);