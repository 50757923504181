import * as React from "react"
import * as $ from 'jquery'
import Menu from "../component/Menu";
import Header from "../component/Header";

class Intro extends React.Component {

    componentDidMount() {
        $('body').css({
            paddingTop: 0
        });
    }

    render() {
        return (
            <div id="container" className="container-fluid overflow-hidden package-page">
                <div className="menu-overlay"/>
                <div className="row">
                    <div className="col-12">
                        <div className="mt-3" style={{textAlign: "center", fontWeight: 600, fontSize: "20px"}}>Giới thiệu dịch vụ iBolero</div>
                        <div className="mt-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontWeight: 600}}>iBolero</span> là kênh nhạc trữ tình chất lượng cao của MobiFone, được cung cấp dưới định dạng video (mp4), không giới hạn lưu lượng data 3G/4G/5G khi truy cập http://ibolero.vn. Khách hàng là thuê bao MobiFone có thể xem, nghe, tải video tại Kênh mà không bị thu thêm bất cứ cước nội dung nào.
                        </div>
                        <div className="mt-3">
                            Dịch vụ được cung cấp qua các kênh sau:<br/>
                            - SMS: đầu số <span style={{fontWeight: 600}}>9355</span><br/>
                            - Wapsite/website: http://ibolero.vn
                        </div>
                        <div className="mt-3">
                            <span style={{fontWeight: 600}}>1.</span> Bạn có thể sử dụng dịch vụ tại http://ibolero.vn nếu bạn là thuê bao MobiFone đang sử dụng gói cước 3G/4G/5G và điện thoại của bạn tương thích với các nền tảng iOS, Android.
                        </div>
                        <div className="mt-3">
                            <span style={{fontWeight: 600}}>2.</span> Bạn có thể thưởng thức hàng ngàn bản nhạc thông qua các chuyên mục của dịch vụ như: Bảng xếp hạng, Bolero kinh điển, Nhạc Phật, Tuyệt đỉnh song ca, Nhạc trữ tình, Bolero remix, Bolero girl, Nhạc vàng, Karaoke.
                        </div>
                        <div className="mt-3">
                            <span style={{fontWeight: 600}}>3. Hướng dẫn Đăng ký dịch vụ iBolero</span><br/>
                            - Thuê bao MobiFone được MIỄN CƯỚC 01 NGÀY sử dụng khi đăng ký dịch vụ lần đầu.<br/>
                            - Khi bạn đang sử dụng 1 gói cước, bạn muốn chuyển qua gói khác thì bạn cần hủy gói hiện có.
                        </div>
                        <table className="table table-bordered mt-3" style={{fontSize: "12px"}}>
                            <thead>
                            <tr>
                                <th scope="col">Gói cước</th>
                                <th scope="col">Mã gói</th>
                                <th scope="col">Giá cước</th>
                                <th scope="col">Cú pháp đăng ký</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Gói ngày</td>
                                <td>BL1</td>
                                <td>3.000đ/ngày</td>
                                <td>Soạn: DK BL1 gửi 9355</td>
                            </tr>
                            <tr>
                                <td>Gói tuần</td>
                                <td>BL7</td>
                                <td>10.000đ/7 ngày</td>
                                <td>Soạn: DK BL7 gửi 9355</td>
                            </tr>
                            <tr>
                                <td>Gói tháng</td>
                                <td>BL30</td>
                                <td>30.000đ/30 ngày</td>
                                <td>Soạn: DK BL30 gửi 9355</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="mt-3">
                            <span style={{fontWeight: 600}}>Lưu ý:</span> Cước dịch vụ sẽ được tính vào hóa đơn cước hàng tháng đối với thuê bao trả sau và trừ vào tài khoản chính đối với thuê bao trả trước.
                        </div>
                        <div className="mt-3">
                            <span style={{fontWeight: 600}}>4. Quyền lợi của thuê bao khi đăng ký gói cước</span><br/>
                        </div>
                        <table className="table table-bordered mt-3" style={{fontSize: "12px"}}>
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">Quyền lợi</th>
                                <th scope="col">Khi đăng ký gói</th>
                                <th scope="col">Khi chưa đăng ký gói</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>Chất lượng video</td>
                                <td>Full HD (1080p)</td>
                                <td>Thấp nhất (360p)</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Tải video</td>
                                <td>Có (Với nhiều lựa chọn: 360p, 480p, 720p, 1080p)</td>
                                <td>Không</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Miễn phí cước data 3G/4G</td>
                                <td>Có</td>
                                <td>Không</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Xem, tải nhạc Độc quyền</td>
                                <td>Có</td>
                                <td>Không</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Miễn phí 1 ngày sử dụng các quyền lợi trên</td>
                                <td>Cho lần đăng ký đầu tiên</td>
                                <td>Không áp dụng</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="mt-3 mb-4">
                            Để biết thêm thông tin chi tiết về dịch vụ, bạn vui lòng liên hệ Tổng đài 9090 (200đ/phút) hoặc soạn HD gửi 9355.
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Intro
