import * as React from "react"
import * as $ from 'jquery'
import {CategoryState} from "../reducer/type/category";
import {AuthState} from "../reducer/type/auth";
import StateInterface from "../reducer/type";
import * as CategoryAction from '../action/category'
import * as AuthAction from '../action/auth'
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import Menu from "../component/Menu";
import Header from "../component/Header";
import * as ls from "local-storage";
import {Redirect} from "react-router";
import LoadingScreen from "../component/LoadingScreen";
import {changeToSlug} from "../helper";
import ModalAlert from "../component/ModalAlert";

interface StatePropsInterface {
    category: CategoryState,
    auth: AuthState
}

interface DispatchPropsInterface {
    actions: {
        getCategoryAction: any,
        postLoginAction: any,
        getInfoAction: any,
        resetErrorAction: any
    }
}

interface ParamPropsInterface {
    history: any
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category,
    auth: state.auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        getCategoryAction: CategoryAction.getCategoryAction,
        postLoginAction: AuthAction.postLoginAction,
        getInfoAction: AuthAction.getInfoAction,
        resetErrorAction: AuthAction.resetErrorAction
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface & ParamPropsInterface

interface CurrentState {
    phone: string,
    password: string,
    loading: boolean
}

class Login extends React.Component <PropsInterface, CurrentState> {

    constructor (props: PropsInterface) {
        super(props);
        this.state = {
            phone: '',
            password: '',
            loading: false
        }
        this.props.actions.getCategoryAction();
    }

    componentDidMount() {
        $(document).ready(function () {
            $('.login-group').css({
                height: ($(window).height() - 74) + 'px'
            })
        });
    }

    componentWillUnmount() {
        this.props.actions.resetErrorAction();
    }

    componentDidUpdate(prevProps) {
        if (this.props.auth.error != prevProps.auth.error) {
            this.setState({
                loading: false
            })
        }
    }

    handlePhone(phone) {
        this.setState({
            phone: phone.target.value
        });
    }

    handlePass(input) {
        this.setState({
            password: input.target.value
        });
    }

    handleSubmit () {
        this.setState({
            loading: true
        })
        this.props.actions.postLoginAction(this.state.phone, this.state.password);
    }

    handleRegister () {
        $('.modal-alert-header').text('Đăng ký');
        $('.modal-alert-text').text('Bạn vui lòng soạn DK BL1 gửi 9355 để đăng ký tài khoản. Xin cảm ơn!')
        $('#modal-alert').modal('show');
        if ( navigator.userAgent.match(/iPhone|iPad|iPod/i) ) {
            $('.modal-alert-login').prop('href', 'sms:9355&body=DK BL1');
        } else if ( navigator.userAgent.match(/Android/i) ) {
            $('.modal-alert-login').prop('href', 'sms:+9355?body=DK BL1');
        }
    }

    handleForget () {
        $('.modal-alert-header').text('Quên mật khẩu');
        $('.modal-alert-text').text('Bạn vui lòng soạn MK gửi 9355 để lấy lại mật khẩu. Xin cảm ơn!')
        $('#modal-alert').modal('show');
        if ( navigator.userAgent.match(/iPhone|iPad|iPod/i) ) {
            $('.modal-alert-login').prop('href', 'sms:9355&body=MK');
        } else if ( navigator.userAgent.match(/Android/i) ) {
            $('.modal-alert-login').prop('href', 'sms:+9355?body=MK');
        }
    }

    render() {
        if (typeof ls.get<string>('token') == 'string') {
            if (this.props.auth.data.is_change_pass == '0') {
                window.location = '/doi-mat-khau' as any;
            } else {
                window.location.replace(window.location.origin);
            }
        }

        let loadingScreen;
        if (this.state.loading) {
            loadingScreen = (<LoadingScreen/>)
        }

        let showError;
        if (this.props.auth.error != null) {
            if (typeof this.props.auth.error[Object.keys(this.props.auth.error)[0]] != "undefined") {
                showError = (
                    <div className="login-error text-danger mb-3 text-center">
                        { this.props.auth.error[Object.keys(this.props.auth.error)[0]][0] }
                    </div>
                )
            }
        }

        return (
            <div className="container-fluid overflow-hidden">
                <div className="menu-overlay"/>
                <Menu/>
                <Header/>
                <ModalAlert text='' button={'sms'}/>
                {loadingScreen}

                <div className="row login-group">
                    <div className="col-12">
                        <div className="login-form">
                            <div className="text-center mb-4 login-form-head">
                                Đăng nhập
                            </div>
                            {showError}
                            <input name="phone" className="form-control" placeholder="Số điện thoại"
                                   value={this.state.phone}
                                   onChange={this.handlePhone.bind(this)}/>
                            <input name="password" className="form-control" type="password" placeholder="Mật khẩu"
                                   value={this.state.password}
                                   onChange={this.handlePass.bind(this)}/>
                            <div className="text-center">
                                <button className="btn login-btn" onClick={this.handleSubmit.bind(this)}>Đăng nhập</button>
                            </div>
                            <div className="text-center login-form-text mt-3">
                                <span onClick={this.handleRegister}>
                                    Đăng ký
                                </span> | &nbsp;
                                <span onClick={this.handleForget}>
                                    Quên mật khẩu
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(Login);